import React, {useContext, useEffect} from "react"
import {Row, Col, Container, Button} from "react-bootstrap"
import LoginLayout from "../components/layouts/loginLayout"
import UnauthenticatedLayout from "../components/layouts/unauthenticatedLayout";
import RegisterButton from "../components/registerButton";
import {useStickyState} from "../hooks/useStickyState";
import {GlobalDispatchContext} from "../context/GlobalContextProvider";
import {GlobalStateContext} from "../context/GlobalContextProvider";
import {navigate} from "gatsby";

const MobileIndexPage = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  // landing on this page will set the IS_MOBILE global state.
  useEffect(() => {
    dispatch({type:"IS_MOBILE"});
  }, []);

  return (
    <UnauthenticatedLayout title="Welcome to Seventeen Days!">
      <Container className="text-center" id="home">
        <Row className="justify-content-center" key={"login-row"}>
          <Col md="6">
            <Button key={"login-button"} variant="primary" size="lg" block onClick={() => navigate("/login/")}>Login</Button>
          </Col>
        </Row>

        <Row className="justify-content-center"  key={"preview-row"}>
          <Col md="6">
            <Button key={"preview-button"} className="half-opacity" variant="light" size="lg" block onClick={() => navigate("/preview/")}>Preview</Button>
          </Col>
        </Row>
        <Row className="justify-content-center"  key={"license-row"}>
          <Col md="6">
            <Button key={"license-button"} className="half-opacity" variant="light" size="lg" block href={"http://seventeendays.org/"}>Learn More</Button>
          </Col>
        </Row>
      </Container>
    </UnauthenticatedLayout>
  )
}

export default MobileIndexPage
